import React from 'react';
import { Box, Container, styled, Typography, useTheme } from '@mui/material';
import MikePhoto from '../../assets/images/joutovsky.png';

const Img = styled('img')(() => ({
    borderRadius: '24px',
    boxShadow: '3px 3px 8px 0px rgba(34, 60, 80, 0.2)',
}));

const Text = styled(Typography)(() => ({
    fontSize: '20px',
    lineHeight: '1.7',
    fontWeight: 300,
}));

const AboutBlock = () => {
    const theme = useTheme();
    return (
        <Container sx={{ my: { md: 18, sm: 10 } }}>
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: { md: 'row', sm: 'column', xs: 'column' },
                    flex: 1,
                }}
            >
                <Box
                    sx={{
                        width: { md: '42%', sm: '100%' },
                        pr: { md: 5, sm: 0 },
                        pt: 2,
                    }}
                >
                    <Img src={MikePhoto} alt="Mikhail Joutovsky" width="100%" />
                </Box>
                <Box
                    sx={{
                        width: { md: '58%', sm: '100%' },
                        pl: { md: 3, sm: 0 },
                        pt: 2,
                    }}
                >
                    <Typography variant="h3" sx={{ mb: 2 }} color={theme.palette.primary.main}>
                        Dr. Mikhail Joutovsky, D.O.
                    </Typography>
                    <Text color={theme.palette.common.darkGrey}>
                        After retiring as a general surgeon in 2019, Dr. Joutovsky found his future calling as an
                        Addiction Medicine Specialist. He completed his fellowship at one of the most advanced programs
                        in the nation, the Medical College of the University of Florida. As an addiction medicine
                        fellow, he worked in hospitals, rehabs, and outpatient clinics to learn more and excel in the
                        field of addiction medicine.
                    </Text>
                    <Text color={theme.palette.common.darkGrey}>
                        Following graduation in 2020, he became the Medical Director of addiction services at MPAP
                        (Montana Professional Assistance Program). There he helped health care workers overcome their
                        addictions through extensive consultations, organizing appropriate treatments, and advocating
                        for them in front of the boards. Now he has opened his private practice which will focus on
                        substance use interventions, sobriety coaching, appropriate consultations of health care
                        professionals, and provision of medically assisted treatment.
                    </Text>
                    <Text color={theme.palette.common.darkGrey}>
                        He actively participates in Caduceus meetings for healthcare workers here in Butte and feels
                        very lucky to be able to provide services the community as an addiction medicine specialist.
                    </Text>
                </Box>
            </Box>
        </Container>
    );
};

export default AboutBlock;
